import { useIsWhiteboardFeatureEnabled } from '@confluence/whiteboard-utils';

/**
 This function is used in the Content Types header to determine if the Watch dialog should
 appear for a specific content type (i.e whiteboards/databases and any future content types)
 */
export const useIsWatchDialogEnabled = (contentType: string | null | undefined): boolean => {
	const { isWhiteboardFeatureEnabled } = useIsWhiteboardFeatureEnabled();

	return contentType === 'whiteboard' && isWhiteboardFeatureEnabled('whiteboardComments');
};
