import type { FC } from 'react';
import React from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import {
	ContentIcon,
	QuickActions,
	Subtitle,
	LastAction,
	StatusLozenge,
	Title,
	SocialProof,
	Excerpt,
	PageLink,
	PageCardContainer,
	AutomationDiscoveryMessage,
} from '../Items';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PopupCard = styled.div({
	display: 'flex',
	flexDirection: 'column',
	width: '400px',
	padding: `${token('space.150', '12px')} ${token('space.200', '16px')} ${token(
		'space.200',
		'16px',
	)} ${token('space.200', '16px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Header = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleContainer = styled.div({
	margin: `${token('space.200', '16px')} 0 ${token('space.050', '4px')} 0`,
	fontSize: '20px',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	overflowWrap: 'break-word',
	display: '-webkit-box',
	WebkitLineClamp: '2',
	WebkitBoxOrient: 'vertical',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FooterContainer = styled.div({
	flex: '1 0 auto',
	display: 'flex',
	alignItems: 'flex-end',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Footer = styled.div({
	width: '100%',
	marginTop: token('space.200', '16px'),
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
});

type PopupViewProps = {
	shouldUseAutomationDiscovery?: boolean; // this should probably go into PageTreeContext, however since this is the only usage, it's ok to leave it here for now
};

export const PopupView: FC<PopupViewProps> = ({ shouldUseAutomationDiscovery }) => {
	// ⚠️ WARNING: Do you need to add conditional logic? Please refer to README#Views ⚠️
	return (
		<PageCardContainer isHoverPageCard>
			<PageLink shouldHideFolder />
			<PopupCard data-testid="page-card-popup-view">
				<Header>
					<ContentIcon />
					<QuickActions actions={['edit', 'star']} />
				</Header>
				<TitleContainer>
					<Title />
				</TitleContainer>
				<Subtitle />
				<StatusLozenge isInColumn />
				<Excerpt />
				<FooterContainer>
					<Footer>
						<LastAction />
						<SocialProof />
					</Footer>
				</FooterContainer>
			</PopupCard>
			<AutomationDiscoveryMessage shouldUseAutomationDiscovery={shouldUseAutomationDiscovery} />
		</PageCardContainer>
	);
};
